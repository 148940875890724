<template>
  <b-row>
    <b-col sm="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="8">
                <form-select ref="regionalSelect" @value-changed="regionalChange" :rules="{required: true}" url="v1/regional_select2" v-model="id_regional" label="Regional"></form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="address" label="Alamat"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-select ref="citySelect" @value-changed="cityChange" :rules="{required: true}" url="v1/city_select2" v-model="id_city" label="Kota"></form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{numeric: true}" v-model="telp" label="Telepon"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="8">
                <form-input :rules="{email: true}" v-model="email" label="Email"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="8">
                <form-input v-model="website" label="Website"></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-select ref="bankSelect1" @value-changed="bank1Change" url="v1/bank_select2" v-model="id_bank_rek1" label="Bank Rekening 1"></form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <form-select ref="bankSelect2" @value-changed="bank2Change" url="v1/bank_select2" v-model="id_bank_rek2" label="Bank Rekening 2"></form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-form-group>
                  <b-form-checkbox v-model="is_main" switch>Pusat</b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-form-group>
                  <b-form-checkbox v-model="is_active" switch>Aktif</b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="mt-3">
                <div>
                  <button-save :isloading="isloading"></button-save>
                  <button-back class="ml-1" :to="{name:'companies'}"></button-back>
                </div>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import { BFormGroup,BFormCheckbox } from 'bootstrap-vue'
import FormValidation from '@/my-components/FormValidation.vue'

export default {
  components:{
    FormInput,
    FormSelect,
    ButtonSave,
    ButtonBack,
    BFormGroup,
    BFormCheckbox,
    FormValidation
  },
  data(){
    return {
      id: this.$route.params.id||null,
      name:'',
      code:'',
      address:'',
      telp:'',
      email:'',
      website:'',
      regional_name:'',
      city:'',
      rek1_name:'',
      rek2_name:'',
      id_city:null,
      id_bank_rek1:null,
      id_bank_rek2:null,
      is_main:false,
      is_active:true,
      id_regional:null,
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'companies/store',
      dispatchShow: 'companies/show',
      dispatchUpdate: 'companies/update',
    }),
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.address = data.address
      this.telp = data.telp
      this.email = data.email
      this.website = data.website
      this.id_city = data.id_city
      this.id_bank_rek1 = data.id_bank_rek1
      this.id_bank_rek2 = data.id_bank_rek2
      this.is_main = data.is_main ? true : false
      this.is_active = data.is_active ? true : false
      this.id_regional = data.id_regional
      this.regional_name = data.regional_name
      this.city = data.city
      this.rek1_name = data.rek1_name
      this.rek2_name = data.rek2_name
      this.$refs.citySelect.initOption([{"value":data.id_city,"label":data.city}])
      this.$refs.regionalSelect.initOption([{"value":data.id_regional,"label":data.regional_name}])
      if (data.id_bank_rek1) this.$refs.bankSelect1.initOption([{"value":data.id_bank_rek1,"label":data.rek1_name}])
      if (data.id_bank_rek2) this.$refs.bankSelect2.initOption([{"value":data.id_bank_rek2,"label":data.rek2_name}])
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const { id,code,name,address,telp,email,website,id_city,id_bank_rek1,id_bank_rek2,is_main,id_regional,regional_name,city,rek1_name,rek2_name } = this
      let params = {id,code,name,address,telp,email,website,id_city,id_bank_rek1,id_bank_rek2,is_main,id_regional,regional_name,city,rek1_name,rek2_name}
      params.is_active = this.is_active ? 1 : 0
      try {
        if (this.id) await this.dispatchUpdate(params)
        else await this.dispatchStore(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'companies'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    regionalChange(json){
      if(json.label) this.regional_name = json.label;
      else this.regional_name = '';
    },
    cityChange(json){
      if(json.label) this.city = json.label;
      else this.city = '';
    },
    bank1Change(json){
      if(json.label) this.rek1_name = json.label;
      else this.rek1_name = '';
    },
    bank2Change(json){
      if(json.label) this.rek2_name = json.label;
      else this.rek2_name = '';
    }
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>